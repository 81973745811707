/* General styles for dashboard */
.dashboard-content {
  padding: 20px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header-container h2 {
  padding-left: 20px;
  text-align: center;
  font-size: 32px;
  color: black;
  font-weight: bold;
}

/* Search Bar Styles */
.search-bar {
  flex-grow: 1;
  max-width: 300px;
  margin: 0 20px;
}

.search-bar input {
  width: 100%;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-container label {
  margin-right: 10px;
  font-weight: bold;
}

.filter-container select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Container for all leads */
.leads-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

/* Each lead box */
.lead-box {
  border: 2px solid #A02724;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
}

/* Lead header for the lead name */
.lead-header {
  font-size: 26px; 
  font-weight: bold;
  color: #A02724;
  position: relative;
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  max-width: calc(100% - 80px);
  display: inline-block;
}

/* Details section of the lead */
.lead-details p {
  font-size: 18px;
  color: #666;
}

/* Buttons inside each lead box */
.lead-buttons {
  display: flex;
  justify-content: space-around;
}

.lead-buttons button {
  background-color: #A02724;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
}

.lead-buttons button:hover {
  background-color: #d9534f;
}

.lead-buttons button:disabled {
  background-color: #A02724;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Styles for assigned or not assigned badge */
.assigned-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  font-size: 14px;
  background-color: #28a745;
}

/* Not assigned style */
.not-assigned {
  background-color: #6c757d;
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination-btn {
  background-color: #A02724;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-btn.active {
  background-color: #d9534f;
}

.pagination-btn:disabled {
  background-color: #646363 !important; 
  cursor: not-allowed;
}

/* Media Queries for Responsive Design */

/* For tablets (max-width: 768px) */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
  }

  h2 {
    font-size: 30px;
  }

  .filter-container {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-container label {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .filter-container select {
    width: 100%;
  }

  .lead-header {
    font-size: 22px;
  }

  .lead-details p {
    font-size: 16px;
  }

  .lead-box {
    padding: 15px;
  }

  .lead-buttons {
    flex-direction: column;
    align-items: center;
  }

  .lead-buttons button {
    width: 100%;
    margin: 10px 0;
  }

  .assigned-badge {
    padding: 6px 10px;
    top: 10px;
    right: 10px;
    font-size: 12px;
  }

  .pagination {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .pagination-btn {
    padding: 8px 15px;
    font-size: 14px;
    min-width: 40px;
  }
}

/* For mobile screens (max-width: 480px) */
/* For mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  h2 {
    font-size: 24px;
  }

  .lead-header {
    font-size: 18px;
    word-break: break-word;
    white-space: normal;
  }

  .lead-details p {
    font-size: 14px;
  }

  .lead-box {
    padding: 10px;
  }

  .lead-buttons button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .lead-buttons {
    flex-direction: column;
    align-items: center;
  }

  .assigned-badge {
    padding: 5px 8px;
    top: 8px;
    right: 10px;
    font-size: 10px;
  }

  .pagination {
    flex-direction: row;
    justify-content: center;
  }

  .pagination-btn {
    width: auto;
    margin: 5px;
    padding: 10px;
    font-size: 14px;
  }

  /* Ensure dropdown stays within screen */
  .filter-container select {
    width: 80%;
    max-width: 100vw; /* Full width on mobile screens */
    box-sizing: border-box; /* Include padding and border in element's total width */
  }

}