/* General NavBar styling */
.navbar {
    background-color: #A02724;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    height: 80px;
}
  
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}
  
.navbar-brand {
    display: flex;
    align-items: center;
    margin-left: 12px;
}
  
.navbar-brand img {
    width: 100px; /* Adjust logo size */
    height: auto;
}
  
.navbar-brand .title {
    font-size: 20px;
    margin-left: 10px;
    color: white;
    margin-top: 15px;
}

/* Desktop Navigation */
.nav-links {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
}
  
.nav-links li {
    padding-top: 20px;
    margin-right: 30px; /* Space between each link */
}
  
.nav-links li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}
  
.nav-links li a:hover {
    text-decoration: underline;
}
  
/* Logout Button */
.login-btn {
    padding-bottom: 8px;
    padding-top: 12px !important; /* Reduce padding-top for alignment */
}

.login-btn a {
    background-color: white !important;
    color: black !important;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
}
  
.login-btn a:hover {
    background-color: #d3d2d2 !important;
    color: #A02724 !important;
}
  
/* Drawer Navigation for Mobile */
.drawer-content {
    width: 80vw; /* 80% width of the viewport */
    height: 100vh; /* Full viewport height */
    background-color: white;
}

.drawer-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.drawer-nav li {
    margin-bottom: 20px; /* Add spacing between items */
}

.drawer-nav li a {
    font-size: 18px; /* Slightly larger font size */
    color: #A02724; /* Red color for links */
    text-decoration: none;
}

.drawer-nav li a:hover {
    color: #FF6347; /* Lighter color on hover */
}
  
/* Hamburger icon adjustments */
.menu-icon {
    margin-top: 10px !important;
    display: none;
    cursor: pointer;
    padding-top: 20px; /* Adjusted padding to move icon down */
    padding-bottom: 10px; /* Adjust bottom padding for balanced spacing */
    align-self: center; /* Center it vertically */
    border: 2px solid white; /* Add outline */
    border-radius: 5px; /* Rounded corners */
    background-color: #A02724; /* Same color as the navbar */
    width: 45px; /* Adjust width */
    height: 45px; /* Adjust height */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Dropdown Styling */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    color: white;
    border: none;
    padding: 0px 5px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 4px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #A02724;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: white;
    color: #A02724;
}

.dropdown:hover .dropdown-content {
    display: block;
}

/* Responsive Dropdown Styling */
@media (max-width: 768px) {
    .menu-icon {
        display: flex; /* Show the hamburger icon on small screens */
        padding-top: 25px; /* Fine-tune alignment */
    }

    .nav-links {
        display: none; /* Hide the regular nav links on small screens */
    }
    .navbar-brand .title {
        font-size: 22px !important;
    }

    /* Dropdown for Mobile View */
    .dropdown {
        text-align: left;
        width: 100%;
    }

    .dropbtn {
        color: #A02724; 
        padding: 10px;
        width: 100%;
        text-align: left;
    }

    .dropdown-content {
        position: static; /* Static positioning for mobile */
        width: 100%; /* Full width for mobile */
        /* background-color: #A02724; */
    }

    .dropdown-content a {
        padding: 10px 15px;
        font-size: 16px;
        color: white !important;
        text-align: left;
    }

    .dropdown-content a:hover {
        background-color: white;
        color: #A02724 !important;
    }
}

/* Media Queries for Larger Screens */
@media (min-width: 769px) {
    .menu-icon {
        display: none !important; /* Ensure the hamburger icon is hidden on large screens */
    }

    .nav-links {
        display: flex; /* Show the navigation links on large screens */
    }

    .login-btn {
        display: flex; /* Ensure logout button is shown on large screens */
    }
    .navbar-brand .title {
        font-size: 22px !important;
    }
}
