/* General Panel Styling */
.task-panel {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  color: black;
  margin-bottom: 20px;
}

/* Add Task Button Styling */
.add-task-button {
  width: 100%;
  padding: 12px;
  background-color: #A02724;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.add-task-button:hover {
  background-color: #d9534f;
}

/* Save and Cancel Button Styling */
.save-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 48%;
}

.save-button:hover {
  background-color: #218838;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.cancel-button {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  width: 48%;
}

.cancel-button:hover {
  background-color: #b5b5b5;
}

.dropdown-admin {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.dropdown-admin-option {
  color: #1a0303 !important;
}

.dropdown-admin option {
  background-color: #ffffff; /* Option background color */
  color: #333; /* Option text color */
}

/* Filter and Sort Options Styling */
.filter-options {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
  justify-content: center;
}

.filter-options label {
  font-weight: bold;
  color: #555;
}

.filter-options select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.filter-options select:hover {
  border-color: #aaa;
}

/* Style for tasks due today */
.due-today {
  background-color: #fffbcc; 
  border: 1px solid #ffeb3b; 
}

.due-today-label {
  color: #ff9800; 
  font-weight: bold;
}



/* Task List Styling */
.task-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 365px; 
  overflow-y: auto;
}

.task-item {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-bottom: 12px;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.task-item:hover {
  background-color: #f7f9fc;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Task Row Styling */
.task-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.task-row input[type="checkbox"] {
  margin-right: 10px;
}

.task-title {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  flex: 1;
}

.task-title:hover {
  text-decoration: underline;
  color: #A02724;
}

/* Task Due Date Styling */
.task-due-date {
  font-size: 14px;
  color: #555;
  margin-right: 15px;
}

/* Missed Task Styling */
.missed-task .task-title {
  color: red;
  font-weight: bold;
}

.missed-label {
  color: red;
  font-weight: bold;
  font-size: 1.0em;
  margin-left: 5px;
}

/* Completed Task Styling */
.completed-task .task-title {
  color: green;
  font-weight: bold;
}

.completed-label {
  color: green;
  font-weight: bold;
  font-size: 1.0em;
  margin-left: 5px;
}

/* Task Details Styling */
.task-details {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 14px;
  color: #333;
  border-left: 3px solid #ccc;
}

.task-details p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

/* Edit and Delete Button Styling */
.edit, .delete, .notify {
  background-color: transparent;
  color: #A02724;
  border: 2px solid #A02724;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.edit:hover, .delete:hover, .notify:hover {
  background-color: #A02724;
  color: #ffffff;
}

.edit {
  margin-right: 5px;
  margin-left: 5px;
}

/* Floating Add Button */
.floating-add-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #A02724;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.floating-add-button:hover {
  background-color: #d9534f;
  transform: scale(1.1);
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px 30px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-content h3 {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

/* Form Group Styling */
.modal-content label {
  font-weight: bold;
  color: #555;
  margin-bottom: 2px !important; /* Reduced gap between label and field */
  display: block;
  text-align: left; /* Left-align labels */
}

.modal-content input[type="text"],
.modal-content input[type="datetime-local"],
.modal-content textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 7px !important;
  box-sizing: border-box;
}

.modal-content textarea {
  resize: vertical;
  min-height: 80px; /* Increased height for better visibility */
}

/* Modal Button Alignment */
.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .task-panel {
    padding: 15px;
  }

  .task-input input,
  .task-input textarea {
    font-size: 14px;
    padding: 10px;
  }

  .task-title {
    font-size: 14px;
  }

  .task-details {
    padding-left: 15px;
  }

  .task-details p {
    font-size: 13px;
  }

  .add-task-button,
  .save-button {
    font-size: 14px;
    padding: 10px;
  }

  .filter-options {
    flex-direction: column;
    align-items: flex-start;
  }

  .task-due-date {
    margin-left: 10px;
    font-size: 10px;
    color: #555;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 28px;
  }

  .task-item {
    padding: 10px;
  }

  .task-title {
    font-size: 13px;
  }

  .task-details p {
    font-size: 12px;
  }

  .delete {
    font-size: 12px;
    padding: 5px 8px;
  }

  .filter-options input,
  .filter-options select {
    width: 100%;
  }

  .add-task-button,
  .save-button {
    width: 100%;
  }
}
