.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure modal is on top */
}

.modal-content {
  background-color: #f8f9fa; /* Light background */
  padding: 20px;
  border-radius: 12px; /* Softer corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  width: 450px;
  max-width: 90%;
  text-align: center; /* Center-align the text */
}

.modal-content h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #343a40; /* Darker font for title */
}

.history-list {
  list-style-type: none;
  padding: 0;
  margin: 15px 0; /* Add margin to space the list */
  max-height: 300px; /* Set a maximum height for the list */
  overflow-y: auto; /* Enable vertical scrolling */
}

.history-list li {
  background-color: #ffffff; /* White background for each item */
  border: 1px solid #e1e1e1; /* Subtle border */
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left; /* Left-align the text */
  font-size: 1em;
  color: #495057; /* Medium dark color for list items */
}

.history-list li:nth-child(odd) {
  background-color: #f1f3f5; /* Slightly darker background for odd items */
}

.modal-actions {
  margin-top: 20px;
  text-align: center;
}

.modal-actions button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
}

.modal-actions button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-weight: bold;
}

/* MEDIA QUERIES FOR RESPONSIVENESS */

/* For tablets and smaller screens (up to 768px) */
@media (max-width: 768px) {
  .modal-content {
    width: 350px;
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 1.6em;
  }

  .history-list li {
    padding: 8px;
    font-size: 0.95em;
  }

  .modal-actions button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

/* For mobile phones and smaller screens (up to 480px) */
@media (max-width: 480px) {
  .modal-content {
    width: 90%; /* Make it occupy most of the screen */
    padding: 10px;
  }

  .modal-content h2 {
    font-size: 1.4em;
  }

  .history-list li {
    padding: 6px;
    font-size: 0.9em;
  }

  .modal-actions button {
    padding: 6px 14px;
    font-size: 0.85em;
  }
}
