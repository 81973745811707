/* Modal Backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 70vh;
    overflow-y: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Modal Heading */
  .modal-content h2 {
    margin-bottom: 15px;
    font-size: 24px;
    color: #333;
    text-align: center;
  }
  
  /* Comments Container */
  .comments-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Comment Box */
  .comment-box {
    background-color: #f9f9f9;  /* Light background for comment box */
    border-radius: 8px;
    padding: 15px;
    text-align: center;          /* Center text */
    color: #333;                 /* Dark text color */
    font-size: 16px;
    font-weight: bold;           /* Make the comment text bold */
    margin: 10px auto;           /* Add margin to center it */
    width: 80%;                  /* Make the box narrower */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);  /* Optional shadow for better appearance */
  }
  
  
  /* Close Button */
  .close-btn {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .close-btn:hover {
    background-color: #0056b3;
  }
  
  /* Make it responsive */
  @media (max-width: 600px) {
    .modal-content {
      padding: 15px;
      width: 95%;
    }
  
    .close-btn {
      font-size: 14px;
      padding: 8px 16px;
    }
  
    .modal-content h2 {
      font-size: 20px;
    }
  
    .comment-box {
      font-size: 14px;
      padding: 8px;
    }
  }
  