.admin-assigned-leads-page {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .leads-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
  }
  
  .leads-table th,
  .leads-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .leads-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .leads-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .leads-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .error-message {
    color: white;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Responsive styles for small screens */
  @media (max-width: 768px) {
    .leads-table,
    .leads-table thead,
    .leads-table tbody,
    .leads-table th,
    .leads-table td,
    .leads-table tr {
      display: block;
    }
  
    .leads-table th {
      display: none;
    }
  
    .leads-table tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      border: 1px solid #ddd;
      background-color: #fff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  
    .leads-table td {
      display: flex;
      justify-content: space-between;
      padding: 12px;
      border: none;
      position: relative;
      padding-left: 50%;
    }
  
    .leads-table td:before {
      content: attr(data-label);
      position: absolute;
      left: 10px;
      width: calc(50% - 10px);
      text-align: left;
      font-weight: bold;
    }
  }
  