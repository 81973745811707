.manage-users-page {
    padding: 20px;
    background-color: #f9f9f9;
}

h2 {
    text-align: center;
    color: #333;
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: bold;
}

/* For large screens: Table layout */
.table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

.users-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.users-table th,
.users-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    color: #333;
}

.users-table th {
    background-color: #f4f4f4;
    color: #555;
    font-weight: bold;
}

.users-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.users-table tr:hover {
    background-color: #f1f1f1;
}

.delete-button {
    background-color: #A02724;
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
    cursor: not-allowed; 
}

.delete-button:hover {
    background-color: #ff3333;
    cursor: not-allowed; 
}
.change-password-button {
    background-color: #A02724;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .change-password-button:hover {
    background-color: #c77372;
  }
/* View Leads Button Styles */
.view-leads-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.view-leads-button:hover {
    background-color: #0056b3;
}

/* Success and error messages */
.success-message, .error-message {
    text-align: center;
    padding: 10px;
    font-size: 16px;
}

.success-message {
    background-color: #4caf50;
    color: white;
    border-radius: 4px;
}

.error-message {
    background-color: #f44336;
    color: white;
    border-radius: 4px;
}

/* Card layout for small screens */
.user-card {
    display: none; /* Hidden by default, shown in media queries */
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.user-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.user-card p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

.delete-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
}

.delete-buttons .delete-button {
    margin-top: 10px; /* Space between buttons */
}

/* Show card layout and hide table for small screens */
@media (max-width: 768px) {
    .table-container {
        display: none; /* Hide the table on small screens */
    }

    .user-card {
        display: block; /* Show user cards on small screens */
    }
}
