.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px; /* Default width */
    max-width: 90%; /* Ensures modal doesn't exceed 90% of the viewport width */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .modal-content {
        width: 90%; /* Adjust width for smaller screens */
        padding: 15px; /* Adjust padding for smaller screens */
    }
}

.leads-list {
    list-style-type: none;
    padding: 0;
    max-height: 300px; /* Set max height for the list */
    overflow-y: auto; /* Enable vertical scrolling */
}

.leads-list li {
    padding: 5px 0;
    border-bottom: 1px solid #ddd; /* Add a divider */
}

.modal-actions {
    margin-top: 20px;
    text-align: right;
}

.error-message {
    color: red;
}

/* Additional Media Queries for very small screens */
@media (max-width: 480px) {
    .modal-content {
        width: 95%; /* Make it occupy more space on very small screens */
        padding: 10px; /* Further reduce padding */
    }

    .leads-list {
        max-height: 150px; /* Adjust max height for smaller screens */
    }
}
