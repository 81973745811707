.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .success-message {
    color: green;
    margin-bottom: 10px;
  }
  
  .save-btn,
  .cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-btn {
    background-color: #28a745;
    color: white;
  }
  
  .cancel-btn {
    background-color: #dc3545;
    color: white;
  }
  
  .save-btn:disabled,
  .cancel-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  
  /* Responsive media queries */
  @media (max-width: 768px) {
    .modal-content {
      width: 95%; /* Adjust the width to take more screen space */
      padding: 15px; /* Reduce padding to accommodate small screens */
    }
  
    .modal-actions {
      flex-direction: column;
      justify-content: center;
    }
  
    .modal-actions button {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .modal-content {
      width: 100%; /* Use the full width on very small screens */
      padding: 10px; /* Even smaller padding */
    }
  
    .modal-actions button {
      padding: 8px 10px;
      font-size: 14px;
    }
  
    .form-group label {
      font-size: 14px;
    }
  }
  