/* Container for the whole page */
.manage-users-page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Move the form slightly down */
}
  
/* Container for the form */
.form-container {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    margin-top: 40px; /* Moves the form down */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
/* Success and Error Messages */
.success-message {
    color: green;
    font-weight: bold;
    margin-bottom: 10px;
}
  
.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}
  
/* Title */
.form-title {
    text-align: center;
    color: black;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
  
/* Form group */
.form-group {
    margin-bottom: 15px;
}
  
.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #A02724;
}
  
.form-group input,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #A02724;
    border-radius: 5px;
}
  
textarea {
    resize: none;
}
  
/* Button Styles */
.form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
  
.cancel-button,
.submit-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #A02724;
    color: white;
    font-weight: bold;
    cursor: pointer;
}
  
.cancel-button {
    background-color: #B0B0B0;
}
  
.cancel-button:hover {
    background-color: #999;
}
  
.submit-button:hover {
    background-color: #EE6F6C;
}
  
/* Responsive Media Queries */
@media (max-width: 768px) {
    .form-container {
        padding: 15px;
        max-width: 100%;
    }

    .form-title {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .form-group input,
    .form-group select {
        padding: 8px;
    }

    .form-buttons {
        flex-direction: column;
        align-items: center;
    }

    .cancel-button,
    .submit-button {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .form-container {
        padding: 10px;
    }

    .form-title {
        font-size: 18px;
    }

    .form-group input,
    .form-group select {
        padding: 8px;
    }

    .cancel-button,
    .submit-button {
        width: 100%;
        margin-bottom: 8px;
    }

    .form-buttons {
        flex-direction: column;
        align-items: center;
    }
}
