/* General modal styling */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* Make the width flexible */
    max-width: 400px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 1em;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    /* For tablets and smaller screens */
    .modal-content {
      width: 95%; /* Reduce the width */
      padding: 15px; /* Reduce padding for small screens */
    }
  
    textarea {
      height: 80px; /* Adjust the textarea height */
      font-size: 0.9em; /* Slightly reduce font size */
    }
  
    .modal-actions button {
      font-size: 0.9em; /* Reduce button font size */
      padding: 8px 12px; /* Adjust padding */
    }
  }
  
  @media (max-width: 480px) {
    /* For smartphones */
    .modal-content {
      width: 98%; /* Almost full width for small screens */
      padding: 10px; /* Further reduce padding */
      max-width: 350px; /* Limit maximum width */
    }
  
    textarea {
      height: 70px; /* Smaller height for text area */
      font-size: 0.85em; /* Reduce font size for mobile */
    }
  
    .modal-actions {
      flex-direction: column; /* Stack buttons vertically */
      gap: 10px; /* Add some space between buttons */
    }
  
    .modal-actions button {
      width: 100%; /* Full width buttons on mobile */
      padding: 10px; /* More padding for easier tapping */
      font-size: 0.85em; /* Adjust button font size */
    }
  }
  