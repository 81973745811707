/* Container for the whole page */
.add-leads-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* Container for the form */
.form-container {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
  margin-top: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.success-message {
  color: green;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Title */
.form-title {
  text-align: center;
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form group */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #A02724;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #A02724;
  border-radius: 5px;
}

textarea {
  resize: none;
}

/* Button Styles */
.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cancel-button,
.submit-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #A02724;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.cancel-button {
  background-color: #B0B0B0;
}

.cancel-button:hover {
  background-color: #999;
}

.submit-button:hover {
  background-color: #EE6F6C;
}

/* Import CSV Button */
/* .import-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px;
} */
.import-button-container {
  display: flex;
  gap: 330px; /* Space between the buttons */
  justify-content: flex-start; /* Align buttons to the left */
  margin-bottom: 8px; /* Add some space below the buttons */
}

.import-button, .export-button {
  background-color: #A02724;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.import-button:hover {
  background-color: #bcbcbc;
  color: #A02724;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Optional: To make the button appear visually disabled */
}

/* Optional: additional styles for visual feedback */
button.uploading {
  background-color: grey;
  pointer-events: none; /* Ensures the button is completely unclickable */
}
.add-leads-page {
  padding: 20px;
}

/* Pop-up for success/error messages */
.pop-up {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336; /* Default red background for errors */
  color: #ffffff !important;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  opacity: 0.95;
  max-width: 50%;
  text-align: center;
}
.pop-up p {
  color: #ffffff !important; /* Force white text on paragraph tag */
  margin: 0; /* Remove margin for a compact look */
}
.pop-up.success {
  background-color: #4caf50; /* Green background for success */
  color: white;
}

/* Modal backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
  text-align: center;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-actions button {
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.modal-actions button:hover {
  background-color: #0056b3;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}


/* Responsive Media Queries */

/* For tablets (max-width: 768px) */
@media (max-width: 768px) {
  .form-container {
    padding: 15px;
    max-width: 100%;
  }

  .form-title {
    font-size: 20px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
  }

  .form-buttons {
    flex-direction: column;
    align-items: center;
  }

  .cancel-button,
  .submit-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .import-button-container {
    justify-content: center; /* Center the buttons for tablets */
    gap: 10px; /* Add space between buttons */
  }

  .import-button, .export-button {
    width: 45%; /* Adjust button width for tablets */
    font-size: 14px;
  }

}

/* For mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  .form-container {
    padding: 10px;
  }

  .form-title {
    font-size: 18px;
  }

  .form-group input,
  .form-group textarea {
    padding: 6px;
    font-size: 14px;
  }

  .form-buttons {
    flex-direction: column;
    align-items: center;
  }

  .cancel-button,
  .submit-button {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .import-button-container {
    flex-direction: row; 
    gap: 50px; /* Add space between stacked buttons */
    justify-content: center;
    /* align-items: center; */
  }

  .import-button, .export-button {
    width: 100%; /* Full width buttons on mobile */
    font-size: 14px;
    padding: 10px 15px; /* Adjust padding for better touch experience */
  }
}

/* For larger screens (min-width: 1024px) */
@media (min-width: 1024px) {
  .form-container {
    padding: 30px;
  }

  .form-title {
    font-size: 28px;
  }

  .form-group input,
  .form-group textarea {
    padding: 12px;
  }

  .cancel-button,
  .submit-button {
    font-size: 16px;
    padding: 12px 30px;
  }
}
