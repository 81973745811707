/* General styles for dashboard */
.dashboard-content {
    padding: 20px;
  }
  
  h2 {
    text-align: center;
    font-size: 40px;
    color: black;
    font-weight: bold;
  }
  
  /* Container for all leads */
  .leads-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between lead boxes */
    padding: 0 20px; /* Left and right padding for spacing */
  }
  
  /* Each lead box */
  .lead-box {
    border: 2px solid #A02724;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px; /* Adds spacing between elements inside lead box */
  }
  
  /* Lead header for the lead name */
  .lead-header {
    font-size: 26px; /* Increased font size for lead name */
    font-weight: bold;
    color: #A02724;
  }
  
  /* Details section of the lead */
  .lead-details p {
    font-size: 18px; /* Increase font size for lead details */
    color: #666;
  }
  
  /* Buttons inside each lead box */
  .lead-buttons {
    display: flex;
    justify-content: space-around; /* Reduce space between buttons */
  }
  
  .lead-buttons button {
    background-color: #A02724;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px; /* Reduce margin between buttons */
    font-size: 16px; /* Increase font size of buttons */
  }
  
  .lead-buttons button:hover {
    background-color: #d9534f;
  }
  
  /* Styles for assigned or not assigned badge */
  .assigned-badge {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px 15px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
  }
  
  .assigned {
    background-color: #28a745; /* Green color for assigned */
  }
  
  .not-assigned {
    background-color: #6c757d; /* Grey color for not assigned */
  }
  
  /* Media Queries for Responsive Design */
  
  /* For tablets (max-width: 768px) */
  @media (max-width: 768px) {
    h2 {
      font-size: 32px; /* Smaller font size for tablets */
    }
  
    .lead-header {
      font-size: 22px; /* Smaller lead name font size */
    }
  
    .lead-details p {
      font-size: 16px; /* Smaller font for lead details */
    }
  
    .lead-box {
      padding: 15px;
    }
  
    .lead-buttons {
      flex-direction: column; /* Stack buttons vertically */
      align-items: center;
    }
  
    .lead-buttons button {
      width: 100%; /* Full width buttons for easier tapping */
      margin: 10px 0; /* Add spacing between buttons */
    }
  
    .assigned-badge {
      padding: 6px 12px; /* Adjust badge size */
      top: 10px;
      right: 10px;
    }
  }
  
  /* For mobile screens (max-width: 480px) */
  @media (max-width: 480px) {
    h2 {
      font-size: 24px; /* Smaller font size for mobile */
    }
  
    .lead-header {
      font-size: 18px; /* Even smaller lead name font size */
    }
  
    .lead-details p {
      font-size: 14px; /* Smaller font for lead details */
    }
  
    .lead-box {
      padding: 10px; /* Smaller padding for mobile */
    }
  
    .lead-buttons button {
      font-size: 14px; /* Smaller button text */
      padding: 8px 16px; /* Adjust button padding */
    }
  
    .lead-buttons {
      flex-direction: column; /* Stack buttons vertically */
      align-items: center;
    }
  
    .assigned-badge {
      padding: 5px 10px;
      top: 8px;
      right: 8px;
    }
  }
  