/* src/FrontEnd/Login/LoginPage.css */

body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #A02724 35%, #EE6F6C 100%);
    position: relative;
    padding: 20px; /* Add padding to prevent text from touching the screen edges */
}

.back-arrow {
    position: absolute;
    top: 20px;
    left: 35px;
    font-size: 50px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.header {
    text-align: center;
    margin-bottom: 30px;
}

.logo-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
}

.title {
    font-size: 30px;
    color: white;
    font-weight: bold;
    margin: 0;
}

.login-box {
    text-align: center;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 320px;
    border: 1px solid #d32f2f;
}

h2 {
    margin-top: 0;
    font-size: 24px;
}

.role {
    font-size: 24px;
    font-weight: bold;
    color: #A02724;
    padding-right: 5px;
}

.login-text {
    font-size: 24px;
    font-weight: bold;
    color: black;
}

p {
    margin-top: 2px;
    margin-bottom: 20px;
    color: #a2a2a2;
    font-size: 14px;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
    margin-left: 0;
}

.form-group label {
    font-size: 16px;
    font-weight: 100rem;
    color: black;
}

.form-group input {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    color: #333;
}

.login-button {
    background-color: #A02724;
    color: white;
    padding: 12px;
    width: 100%;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #EE6F6C;
}

.error-message {
    color: red;            /* Display the error message in red */
    font-size: 14px;       /* Set the font size */
    margin-top: 10px;      /* Add some margin for spacing */
  }

/* Media Queries for responsiveness */

/* Tablet and small screens */
@media only screen and (max-width: 768px) {
    .back-arrow {
        font-size: 30px;
        left: 15px;
    }

    .logo {
        width: 70px;
    }

    .title {
        font-size: 24px;
    }

    .login-box {
        width: 280px;
        padding: 30px;
    }

    h2, .role, .login-text {
        font-size: 22px;
    }

    .form-group input {
        padding: 8px;
    }

    .login-button {
        font-size: 14px;
        padding: 10px;
    }
}

/* Mobile screens */
@media only screen and (max-width: 480px) {
    .back-arrow {
        font-size: 40px;
        font-weight: bold;
        left: 30px;
        top: 30px;
    }

    .logo {
        width: 60px;
    }

    .title {
        font-size: 22px;
        padding-left: 3px;
    }

    .login-box {
        width: 250px;
        padding: 20px;
    }

    h2, .role, .login-text {
        font-size: 20px;
    }

    .form-group input {
        padding: 8px;
        font-size: 13px;
    }

    .login-button {
        font-size: 12px;
        padding: 8px;
    }
}
