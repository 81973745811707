/* Notification Page Styling */
.notification-page {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    transition: all 0.3s ease;
}

.notification-title {
    font-size: 24px;
    font-weight: bold;
    color: #A02724;
    text-align: center;
    margin-bottom: 20px;
}

/* Notification List Container */
.notification-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px;
}

/* Custom Scrollbar Styling */
.notification-list::-webkit-scrollbar {
    width: 8px;
}

.notification-list::-webkit-scrollbar-thumb {
    background-color: #A02724;
    border-radius: 8px;
}

.notification-list::-webkit-scrollbar-thumb:hover {
    background-color: #A02724;
}

/* Individual Notification Item */
.notification-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s, box-shadow 0.3s;
    position: relative;
}

.notification-item:hover {
    background-color: #eaf4ff;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.1);
}

/* Notification Icon */
.notification-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #007bff;
    background-color: #e6f3ff;
    border-radius: 50%;
    margin-right: 15px;
}

/* Notification Content */
.notification-content {
    flex-grow: 1;
}

.notification-message {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin: 0;
}

.notification-timestamp {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    display: block;
}

.no-notifications {
    text-align: center;
    font-size: 16px;
    color: #999;
    padding: 20px;
}

/* Responsive Styling */
@media (max-width: 600px) {
    .notification-page {
        max-width: 90%;
        padding: 15px;
    }

    .notification-title {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .notification-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .notification-icon {
        width: 35px;
        height: 35px;
        font-size: 16px;
        margin-bottom: 8px;
    }

    .notification-message {
        font-size: 15px;
    }

    .notification-timestamp {
        font-size: 11px;
    }
}
