.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
}

.user-list {
  list-style-type: none;
  padding: 0;
  max-height: 310px; /* Set max height to allow scroll when more than 5 users */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ddd; /* Optional: Add border for better visual separation */
  margin: 10px 0; /* Add margin to the list */
}

.user-item {
  display: flex;
  align-items: center; /* Align circle and label vertically in the center */
  margin-bottom: 10px;
  padding-left: 15px;
}

.assign-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
}

.assign-btn:hover {
  background-color: #0056b3;
}

.circle-checkbox {
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}

.circle-checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.circle-checkbox:checked::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
}

.checkbox-label {
  font-size: 16px;
  vertical-align: middle; /* Ensure the text aligns vertically in the middle */
}

.assigned-tick {
  color: green;
  font-size: 1.2em;
  margin-right: 15px;
}

.modal-actions {
  margin-top: 20px;
  text-align: right;
}

.error-message {
  color: red;
}

/* Media Queries for Small Screens */

/* For devices with screen width of 768px or less (Tablets and smaller devices) */
@media (max-width: 768px) {
  .modal-content {
      width: 90%; /* Make the modal take most of the screen width */
      padding: 15px; /* Reduce padding */
  }

  .user-item {
      flex-direction: column; /* Stack user name and button vertically */
      align-items: flex-start; /* Align items to the start */
  }

  .assign-btn {
      margin-top: 10px; /* Add space between username and button */
  }
}

/* For devices with screen width of 480px or less (Mobile phones) */
@media (max-width: 480px) {
  .modal-content {
      width: 95%; /* Maximize the modal width on small screens */
      padding: 10px; /* Further reduce padding for small devices */
  }

  .user-item {
      flex-direction: column;
      align-items: flex-start;
  }

  .assign-btn {
      width: 100%; /* Make the button take full width on mobile */
      padding: 8px; /* Increase padding for easier tapping */
      font-size: 1em; /* Adjust font size */
  }

  .modal-actions {
      text-align: center; /* Center the close button on small screens */
  }
}
