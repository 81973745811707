body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #A02724 35%, #EE6F6C 100%);
    padding: 10px !important;
}

.header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px !important;
}

.logo-title {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.logo {
    width: 150px !important; 
    height: auto;
    margin-bottom: 2px !important; 
}

.title {
    font-size: 30px !important;
    color: white;
    font-weight: bold;
    margin: 0;
}

.login-box {
    text-align: center;
    background-color: white;
    padding: 50px;
    border-radius: 15px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 320px;
}

h2 {
    margin-top: 10px;
    font-size: 28px;
    color: #c43c3f;
    margin-bottom: 15px;
}

p {
    margin-bottom: 20px;
    color: #666;
    font-size: 14px;
}

.button-group {
    display: flex;
    flex-direction: column;
}

.login-button {
    background-color: #A02724;
    color: white;
    padding: 12px 20px;
    margin: 12px 0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px; 
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-button:hover {
    background-color: #EE6F6C;
    transform: translateY(-3px);
}

.login-button:active {
    transform: translateY(0px);
}

/* Media Queries for responsiveness */

@media only screen and (max-width: 768px) {
    .title {
        font-size: 30px; 
    }

    .login-box {
        width: 280px; 
        padding: 40px;
    }

    h2 {
        font-size: 28px;
    }

    p {
        font-size: 14px;
    }

    .login-button {
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {
    .login-container {
        padding: 10px;
        height: 100vh; 
    }

    .logo {
        width: 110px;
        margin-bottom: 8px;
    }

    .title {
        font-size: 24px; 
    }

    .login-box {
        width: 250px; 
        padding: 30px;
    }

    h2 {
        font-size: 24px; 
    }

    p {
        font-size: 14px;
    }

    .login-button {
        font-size: 14px; 
        padding: 10px 15px;
    }
}
