/* General styles for dashboard */
.dashboard-content {
  padding: 20px;
}

.header-container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Add space between title and search bar */
  margin-bottom: 20px; /* Adjust spacing below the header */
}

.header-container h2 {
  padding-left: 20px;
  text-align: center;
  font-size: 32px;
  color: black;
  font-weight: bold;
}

/* Search Bar Styles */
.search-bar {
  flex-grow: 1; /* Allow search bar to grow */
  max-width: 300px; /* Maximum width for larger screens */
  margin: 0 20px; /* Space around the search bar */
}

.search-bar input {
  width: 100%; /* Full width for search input */
}

/* Adjusted dropdown button */
.dropdown-btn {
  background: none;  /* Remove background color */
  border: none;  /* Remove border */
  cursor: pointer;  /* Make it clickable */
  outline: none;  /* Remove focus outline */
  padding: 0;  /* Remove padding */
  font-size: 16px; /* Adjust the size of the arrow */
  position: absolute; /* Position the button absolutely */
  right: -80px; /* Align the button to the right */
  top: 50%; /* Vertically center the button */
  transform: translateY(-50%); /* Adjust vertical centering */
}

.lead-header {
  position: relative; /* Make this element a containing block for the absolute positioning of the dropdown button */
  display: flex;
  align-items: center;
  justify-content: space-between; /* This ensures the text stays on the left and the button moves to the right */
  padding: 10px;
  font-size: 26px;
  font-weight: bold;
  color: #A02724;
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  max-width: calc(100% - 80px); /* Ensure it leaves space for the 'Not Assigned' badge */
}

/* Adjust dropdown arrow size */
.dropdown-btn::after {
  /* content: "▼";  Use the arrow symbol here */
  font-size: 18px;
  color: #A02724;  /* Match the color with the lead header text */
}


/* Container for all leads */
.leads-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between lead boxes */
  padding: 0 20px; /* Left and right padding for spacing */
}

/* Each lead box */
.lead-box {
  border: 2px solid #A02724;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adds spacing between elements inside lead box */
  background-color: #fff; /* White background for lead boxes */
}

/* Lead header for the lead name */
.lead-header {
  font-size: 26px; 
  font-weight: bold;
  color: #A02724;
  position: relative;
  word-break: break-word;   /* Allows breaking long words */
  white-space: normal;      /* Allows wrapping onto multiple lines */
  overflow-wrap: break-word; /* Ensures text wraps to the next line */
  max-width: calc(100% - 80px); /* Ensure it leaves space for the 'Not Assigned' badge */
  display: inline-block;     /* Keep the header and badge inline */
}

/* Details section of the lead */
.lead-details p {
  padding-left: 10px;
  font-size: 18px; /* Increase font size for lead details */
  color: #666;
}

/* Buttons inside each lead box */
.lead-buttons {
  display: flex;
  justify-content: space-around; /* Horizontal layout for large screens */
}

.lead-buttons button {
  background-color: #A02724;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px; /* Add margin between buttons */
}

.lead-buttons button:hover {
  background-color: #d9534f;
}

/* Disabled button style for the Delete button */
.lead-buttons button:disabled {
  background-color: #A02724; /* Light grey background for disabled button */
  cursor: not-allowed; /* Disabled cursor */
  opacity: 0.6; /* Slightly faded look */
}

/* Styles for assigned or not assigned badge */
.assigned-badge {
  position: absolute;
  top: 35px; /* Adjust this value to anchor it closer to the top */
  right: 50px; /* Anchor it to the right with a small margin */
  padding: 8px 12px; /* Add some padding for better visibility */
  border-radius: 5px;
  font-weight: bold;
  color: white;
  font-size: 14px; /* Adjusted font size */
  background-color: #28a745; /* Green color for assigned */
}

/* Not assigned style */
.not-assigned {
  background-color: #6c757d; /* Grey color for not assigned */
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px; /* Space between pagination buttons */
  margin-top: 20px;
}

.pagination-btn {
  background-color: #A02724;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-btn.active {
  background-color: #d9534f; /* Different color for active page */
}

.pagination-btn:disabled {
  background-color: #646363 !important; 
  cursor: not-allowed;
}

/* Media Queries for Responsive Design */

/* For tablets (max-width: 768px) */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column; /* Stack the title and search bar */
    align-items: flex-start; /* Align items to the start */
  }

  .search-bar {
    margin-left: 0; /* Remove margin on smaller screens */
    width: 100%; /* Make search bar full width */
    margin-top: 10px; /* Add some spacing above the search bar */
  }

  h2 {
    font-size: 30px; /* Smaller font size for tablets */
  }

  .lead-header {
    font-size: 22px; /* Smaller lead name font size */
  }

  .lead-details p {
    font-size: 16px; /* Smaller font for lead details */
  }

  .lead-box {
    padding: 15px;
  }

  /* Stack buttons vertically for tablets */
  .lead-buttons {
    flex-direction: column;
    align-items: center;
  }

  .lead-buttons button {
    width: 100%; /* Full-width buttons for easier tapping */
    margin: 10px 0; /* Add spacing between buttons */
  }

  .assigned-badge {
    padding: 6px 10px; /* Adjust badge size */
    top: 20px;
    right: 30px;
    font-size: 12px; /* Smaller font for tablet */
  }

  /* Pagination for tablets */
  .pagination {
    justify-content: space-between; /* Use space-between to distribute items */
    flex-wrap: wrap; /* Allow pagination items to wrap */
  }

  .pagination-btn {
    padding: 8px 15px; /* Adjust padding for smaller buttons */
    font-size: 14px; /* Smaller font size for pagination */
    min-width: 40px; /* Allow buttons to shrink */
  }
}

/* For mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  h2 {
    font-size: 24px; /* Smaller font size for mobile */
  }

  .lead-header {
    font-size: 18px; /* Even smaller lead name font size */
    word-break: break-word; /* Force words to break if they are too long */
    white-space: normal;
  }

  .lead-details p {
    font-size: 14px; /* Smaller font for lead details */
  }

  .lead-box {
    padding: 10px; /* Smaller padding for mobile */
  }

  .lead-buttons button {
    font-size: 14px; /* Smaller button text */
    padding: 8px 16px; /* Adjust button padding */
  }

  /* Stack buttons vertically for mobile */
  .lead-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
  }

  .assigned-badge {
    padding: 5px 8px; /* Adjust badge size */
    top: 20px;
    right: 30px;
    font-size: 10px; /* Smaller font for mobile */
  }

  /* Pagination for mobile */
  .pagination {
    flex-direction: row; /* Maintain row direction */
    justify-content: center; /* Center items */
  }

  .pagination-btn {
    width: auto; /* Auto width for buttons to fit */
    margin: 5px; /* Space between pagination buttons */
    padding: 10px; /* Increase padding for better touch target */
    font-size: 14px; /* Font size for pagination */
  }
}
